import generic from './generic';
import ksu from '../images/reviews/ksu.png';
import cover from '../images/covers/prague.jpg';

export default {
    ...generic,
    slug: 'prague',
    cover,
    collections: [
      "review", 
      "unusual",  
    ],
    cityNames: ['Прага', 'Праге'],
    reviews: [
      {
        avatar: ksu,
        name: "Ксюша",
        text: "В экскурсии был аудиогид. Мне очень понравилась озвучка — колоритный голос, с юмором и очень органичный и выразительный.",
        product: {
          name: "Прогулка с призраком Старого Города",
          link: "https://surprizeme.ru/ru/prague/review/prizrak-starogo-goroda/"
        }
      }
    ]   
};